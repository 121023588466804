import { useEffect } from "react";

function ContactUs() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Contact us");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "View contact us of our website.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/cHd7QXtM/logo192.jpg");
    },[])
    return (
        <div className="container middle">
            <div className="card shadow p-2" style={{marginTop: "70px"}}>
            <h1>Contact us</h1>
            <hr/>
            If you have any questions, comments, or concerns about our website, please do not hesitate to contact us. You can reach us through any of the below ways.
            <br/>
            <br/>
            <p>Email: <a href="mailto:contact.funsite.in@gmail.com" className="text-decoration-none">contact.funsite.in@gmail.com</a></p>
            <p>Instagram: <a target="_blank" href="https://www.instagram.com/funsite_socialmedia/" className="text-decoration-none">funsite_socialmedia</a></p>
            </div>
        </div>
    )
}

export default ContactUs;