import { useEffect } from "react";

function AboutUs() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "About us");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "View about us of our website.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/cHd7QXtM/logo192.jpg");
    },[])
    return (
        <div className="container middle">
            <div className="card shadow p-2" style={{marginTop: "70px"}}>
            <h1>About us</h1>
            <hr/>
            <p>Welcome to our website,<br/>
            <br/>
                We provide various activities, 
                whether you want to send warmful wishes 
                or you're in the mood for some fun and want to play a prank on your friends or family, 
                or ask various questions or share tips,
                or participate in a reward winning contest,  
                At our website, we strive to provide our users with the best possible experience. 
                So, whether you want to wish your loved ones on a special occasion, play a prank on your friends or engage in other provided activities, you can count on us to provide what you need.
                <br/>
                <br/>
                Note:- This is a developing website, users may not find more number of activities or suitable activities for them but we are trying to add more activities and enhance  
                our website to give our users best possible experience.
                <br/>
                <br/>
                Thank you for visiting our website.
            </p>
            </div>
        </div>
    )
}

export default AboutUs;