import { useEffect } from "react";

function TermsAndConditions() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Terms and Conditions");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "View our website terms and conditions.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/cHd7QXtM/logo192.jpg");
    },[])
    return (
        <div className="container pt-5">
            <div className="card shadow p-2 mt-3">
                <h1>Terms and conditions</h1>
                <hr />
                <p>
                    Welcome to our website. By using this website, you acknowledge and agree to the following terms and conditions.
                    <br/>
                    <br/>
                    1. User Information: Some of the activities on this website may collect information. Any information collected by these activities are subject to the privacy policy of our website.
                    <br/>
                    <br/>
                    2. Use of Activities: The Activies provided on this website are for entertainment purposes only. We do not guarantee the accuracy, completeness, or suitability of the activities provided, and we are not responsible for any consequences that may arise from the use of the activities.
                    <br/>
                    <br/>
                    3. Responsibility: We do not endorse or promote any illegal or harmful activities, and we strongly advise our users to use our website responsibly and at their own discretion. We are not responsible for any damages or losses that may result from the use our website, including but not limited to, loss of data, loss of revenue, or any other consequential damages.
                    <br/>
                    <br/>
                    4. Modification: We reserve the right to modify, suspend, or discontinue the website, its content, or any part of it at any time without prior notice. We also reserve the right to modify or update these terms and conditions at any time without prior notice.
                    <br/>
                    <br/>
                    5. Copyright: All content on this website, including but not limited to text, graphics, images, and logos, is the property of the website owner or its content suppliers and is protected by international copyright laws. Unauthorized use, reproduction, or distribution of any content on this website is prohibited.
                    <br/>
                    <br/>
                    6. Indemnification: You agree to indemnify and hold us harmless from any claims, damages, or expenses arising from your use of this website.
                    <br/>
                    <br/>
                    7. Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which the website owner is located.
                    <br/>
                    <br/>
                    8. Advertising: Our website may display advertisements from third-party advertisers. We are not responsible for the content of these advertisements or any consequences, damages and losses that may arise from clicking on them.
                    <br/>
                    <br/>
                    9. Disclaimer of Endorsement: The inclusion of any third-party advertisements or affiliate links on our website does not constitute an endorsement or recommendation by us.
                    <br/>
                    <br/>
                </p>
                <p>By using this website, you acknowledge that you have read and agree to these terms and conditions. If you do not agree to these terms and conditions, please do not use this website.</p>
            </div>
        </div>
    )
}

export default TermsAndConditions;