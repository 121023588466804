import { Link } from "react-router-dom";
import { useEffect } from "react";

function Pranks() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Pranks");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Prank your contacts and have a laugh with them.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/PqZmw06R/Prankscard.jpg");
    },[])
    return (
        <div className="container">
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
            <div className="col">
                <Link to="/pranks/aprilfool" className="text-decoration-none"><div className="card h-100">
                    <img src="images\AprilFool.jpg" className="card-img-top" alt="April fool"/>
                        <div className="card-body">
                            <h5 className="card-title">April Fool</h5>
                            <p className="card-text">Fool your contacts on April fool's day by making them think their three recents whatsapp chats are being deleted.</p>
                        </div>
                </div></Link>
            </div>
            <div className="col">
                <Link to="/pranks/valentinetips" className="text-decoration-none"><div className="card h-100">
                    <img src="images\ValentineTips.jpg" className="card-img-top" alt="Valentine tips"/>
                        <div className="card-body">
                            <h5 className="card-title">Valentine Tips</h5>
                            <p className="card-text">Play a joke on your single friends by reminding them that they are single on this Valentines's day.</p>
                        </div>
                </div></Link>
            </div>
        </div>
        </div>
    )
}

export default Pranks;