import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import AprilFool from './components/Pranks/AprilFool';
import AboutUs from './components/Menu/AboutUs';
import NavBar from './components/Home/NavBar';
import ContactUs from './components/Menu/ContactUs';
import TermsAndConditions from './components/Menu/TermsAndConditions';
import PrivacyPolicy from './components/Menu/PrivacyPolicy';
import Ramzan from './components/Greetings/Ramzan';
import ValentineTips from './components/Pranks/ValentineTips';
import Home from './components/Home/Home';
import Pranks from './components/Pranks/Pranks';
import Greetings from './components/Greetings/Greetings';
import Ugadi from './components/Greetings/Ugadi';
import Questions from './components/Questions/Questions';
import SummerTips from './components/Home/SummerTips';
import QuesResult from './components/Questions/QuesResult';


function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyDYFuBRg3IYbLiquxfKVnJrC0FxOG3mI_k",
    authDomain: "funsite-in.firebaseapp.com",
    projectId: "funsite-in",
    storageBucket: "funsite-in.appspot.com",
    messagingSenderId: "581660078283",
    appId: "1:581660078283:web:629077ffd80d06bf03ab4e",
    measurementId: "G-XB2XPY3VYD"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path='/pranks' element={<Pranks />} />
        <Route path='/greetings' element={<Greetings />} />
        <Route path="/pranks/aprilfool" element={<AprilFool />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path='/termsandconditions' element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path='/greetings/eid-ul-fitr' element={<Ramzan />} />
        <Route path='/greetings/ugadi' element={<Ugadi />} />
        <Route path="/pranks/valentinetips" element={<ValentineTips />} />
        <Route path='/questions' element={<Questions/>}/>
        <Route path='/questions/:id' element={<QuesResult/>}/>
        <Route path='/summertips' element={<SummerTips/>}/>
        <Route path='/' element={<Home/>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <div className='text-center mt-2'>
        <footer className="bg-dark">
          <div className="p-3 text-white">
            <p className="text-white">© 2023 Copyright: funsite.in</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
