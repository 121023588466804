import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import UgadiBg from "../../assets/UgadiBg.jpg";
import { useEffect } from "react";

function Ugadi() {
    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Happy Ugadi");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Wish your contacts Happy Ugadi.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/8zxRsTbv/Ugadi-Card.jpg");
    },[])

    return (
        <div>
            <div className="icon-bar">
                <button className="btn btn-success" onClick={modalShow}><FontAwesomeIcon icon={faShareNodes} /></button>
            </div>
            <Modal centered show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <div className="text-center">
                    <div>
                        <a href={`whatsapp://send?text=${window.location.href}`} data-action="share/whatsapp/share"
                            target="_blank" rel="noreferrer"><button className="btn btn-success"> Share to WhatsApp</button></a>
                        <button className="btn btn-primary m-3" id="copyButton" onClick={() => { navigator.clipboard.writeText(`${window.location.href}`); document.getElementById("copyButton").textContent = "Copied!" }}>Copy link</button>
                    </div>
                </div>

                <Modal.Footer>
                    <Button variant="secondary" onClick={modalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <img src={UgadiBg} className="middle mt-5" alt="Happy Ugadi"/>
        </div>
    )
}

export default Ugadi;