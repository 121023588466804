import { Button, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { deleteDoc, doc, onSnapshot } from "firebase/firestore";
import db from "../../dbConfig";
import { useNavigate } from "react-router-dom";

function QuesCreator(props) {
    const [show, setShow] = useState(false);
    const [myReplies, setMyReplies] = useState([]);
    const [repliesError, setRepliesError] = useState("");
    const [deleteError, setDeleteError] = useState("");
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);
    const id = props.data.id;
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const userReplies = onSnapshot(doc(db, "questions", id), (doc) => {
                const getReplies = doc.data().replies;
                setMyReplies(getReplies.reverse());
                setRepliesError("");
            })
            return userReplies;
        }
        catch (error) {
            setRepliesError("Couldn't get your replies, error occurred - try again later");
        }
    }, [])

    const handleDelete = async () => {
        try {
            localStorage.removeItem(props.data.question);
            await deleteDoc(doc(db, "questions", id));
            setDeleteError("");
            navigate('/questions');
        }
        catch (error) {
            setDeleteError("Couldn't delete, error occuured - try again later");
        }
    }

    return (
        <div className="container d-flex flex-column justify-content-center pt-3">
            <div className="card shadow mt-5">
                <div className="card-header text-center">
                    <h5>Questions</h5>
                </div>
                <div className="card-body">
                    <div style={{ textAlign: "right" }}>
                        <button className="btn btn-danger" onClick={modalShow}>Delete</button>
                    </div>
                    <Modal centered show={show} onHide={modalClose}>
                        <Modal.Header closeButton>
                            <h5>Delete page</h5>
                        </Modal.Header>
                        <div className="text-center">
                            <p>This will delete all the data in our database and your localstorage with respect to this personal page.</p>
                            <p>Once deleted, you cannot retrieve your data and this page.</p>
                            <p>Click 'Confirm' to delete.</p>
                        </div>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDelete}>Confirm</Button>
                            <Button variant="secondary" onClick={modalClose}>Close</Button>
                            <p className="text-danger">{deleteError}</p>
                        </Modal.Footer>
                    </Modal>
                    <h6 className="text-center"><i>Hi {props.data.creatorName}</i></h6>
                    <p className="text-center"><b><u>Your Question</u></b></p>
                    <h4 className="text-center">{props.data.question}</h4>
                    <div className="card">
                        <div className="card-header text-center">
                            Your replies
                        </div>
                        <div className="card-body">
                            <p className="text-danger">{repliesError}</p>
                            {myReplies.length > 0 ?
                                myReplies.map((ans) => (
                                    <div className="card m-3 border-dark p-2">
                                        <p>{ans.reply}</p>
                                        <p className="blockquote-footer" style={{ textAlign: "right" }}><i>{ans.repliersName}</i></p>
                                    </div>
                                ))
                                :
                                <p className="text-center text-muted">No replies yet</p>
                            }
                        </div>
                    </div>
                    <div className="text-center pt-3">
                        <p>Share the link to your contacts to get replies</p>
                        <a href={`whatsapp://send?text=To reply to ${props.data.creatorName}'s question - ${props.data.question}, click this link 👉 ${window.location.href}`} data-action="share/whatsapp/share"
                            target="_blank" rel="noreferrer"><button className="btn btn-success">Share to WhatsApp</button></a>
                        <button className="btn btn-primary m-3" id="copyButton" onClick={() => { navigator.clipboard.writeText(`To reply to ${props.data.creatorName}'s question - ${props.data.question}, click this link 👉 ${window.location.href}`); document.getElementById("copyButton").textContent = "Copied!" }}>Copy link</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuesCreator;