import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import db from "../../dbConfig";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import "firebase/firestore";
import QuesCreator from "./QuesCreator";
import QuesCreatorUser from "./QuesCreatorUser";


function QuesResult() {
    const { id } = useParams();
    const [idCheck, setIdCheck] = useState(true);
    const [dbQues, setDbQues] = useState("");
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();

    async function paramValidation() {
        const docRef = doc(db, "questions", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setIdCheck(true);
            setDbQues(docSnap.data().question);
            setUserData(docSnap.data());
            document.querySelector('meta[property="og:title"]').setAttribute("content", `${docSnap.data().creatorName}'s Question`);
            document.querySelector('meta[property="og:description"]').setAttribute("content", `${docSnap.data().question}`);
            document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/52zhX2Vd/Questions.jpg");
        }
        else {
            setIdCheck(false);
            setDbQues("");
            setUserData([]);
            navigate('/questions');
        }
    }
    useEffect(() => {
        paramValidation();
    },[])
    return (
        idCheck && id === localStorage.getItem(dbQues) ? <QuesCreator data={userData} /> : <QuesCreatorUser data={userData} />
    )
}

export default QuesResult;