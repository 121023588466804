import { useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Funsite");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Our website provides different activites to share with your friends and family or to your contacts.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/cHd7QXtM/logo192.jpg");
    },[])
    return (
        <div className="container">
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
            <div className="col">
                <Link to="/questions" className="text-decoration-none"><div className="card h-100">
                    <img src="images\Questions.jpg" className="card-img-top" alt="Peron with question mark infront on him"/>
                        <div className="card-body">
                            <h5 className="card-title">Questions</h5>
                            <p className="card-text">Question your friends, family or contacts and see what they have answered. You can pick questions provided in the dropdown or customize your own question.</p>
                        </div>
                </div></Link>
            </div>
            <div className="col">
                <Link to="/greetings" className="text-decoration-none"><div className="card h-100">
                    <img src="images\Greetings.jpg" className="card-img-top" alt="Greetings"/>
                        <div className="card-body">
                            <h5 className="card-title">Greetings</h5>
                            <p className="card-text">Checkout our greetings and wish your contacts on those occasions by sharing the appropriate greeting.</p>
                        </div>
                </div></Link>
            </div>
            <div className="col">
                <Link to="/pranks" className="text-decoration-none"><div className="card h-100">
                    <img src="images\Prankscard.jpg" className="card-img-top" alt="Joker Emoji"/>
                        <div className="card-body">
                            <h5 className="card-title">Pranks</h5>
                            <p className="card-text">Prank your contacts and have a laugh with them.</p>
                        </div>
                </div></Link>
            </div>
            <div className="col">
                <Link to="/summerTips" className="text-decoration-none"><div className="card h-100">
                    <img src="images\SummerTipsCard.jpg" className="card-img-top" alt="Summer tips card"/>
                        <div className="card-body">
                            <h5 className="card-title">Summer Tips</h5>
                            <p className="card-text">Share some Tips with yours contacts for this hot summer.</p>
                        </div>
                </div></Link>
            </div>
        </div>
        </div>
    )
}

export default Home;