import { useState } from "react";
import { Link } from "react-router-dom";
import db from "../../dbConfig";
import { updateDoc, doc, arrayUnion } from "firebase/firestore";

function QuesCreatorUser(props) {
    const [nameValue, setNameValue] = useState("");
    const [buttonValid, setButtonValid] = useState(false);
    const [taValue, setTaValue] = useState("");
    const [taButtonValid, setTaButtonValid] = useState(true);
    const [nameError, setNameError] = useState("");
    const [taError, setTaError] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [successValid, setSuccessValid] = useState(true);
    
    const handleNameValidation = (e) => {
        const name = e.target.value;
        if(name!==""){
            if(name.match(/^([a-zA-Z0-9]+[\s])*[a-zA-z0-9]+$/) && !(name.includes("[") || name.includes("]") || name.includes("[") || name.includes("^") || name.includes("\\"))){
                setButtonValid(false);
                setNameValue(name);
                setNameError("");
            }
            else{
                setButtonValid(true);
                setNameValue(name);
                setNameError("only alphabets, numbers and single space between letters are allowed");
            }
        }else{
            setButtonValid(false);
            setNameValue("");
            setNameError("");
        }
    }

    const handleTextArea = (e) => {
        const reply = e.target.value;
        if(reply !== ""){
            if(reply.match(/^([a-zA-Z0-9.,]+[\s])*[a-zA-z0-9,.]+$/) && !(reply.includes("[") || reply.includes("]") || reply.includes("[") || reply.includes("^") || reply.includes("\\"))){
                setTaButtonValid(false);
                setTaValue(reply);
                setTaError("");
            }
            else{
                setTaButtonValid(true);
                setTaValue(reply);
                setTaError("only alphabets, numbers, (.), (,) and single space between letters are allowed");
            }
        }
        else{
            setTaButtonValid(true);
            setTaValue("");
            setTaError("");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            if(nameValue === ""){
                const inputs = {
                    repliersName: "Anonymous",
                    reply: taValue,
                    time: new Date()
                }
                await updateDoc(doc(db, "questions", props.data.id), {
                    replies: arrayUnion(inputs)
                }).then(()=>{
                    setNameValue("");
                    setTaValue("");
                    setErrorMsg("");
                    setSuccessValid(false);
                    setTimeout(()=>setSuccessValid(true), 3000)
                })
            }
            else{
                const inputs = {
                    repliersName: nameValue,
                    reply: taValue,
                    time: new Date()
                }
                await updateDoc(doc(db, "questions", props.data.id), {
                    replies: arrayUnion(inputs)
                }).then(()=>{
                    setNameValue("");
                    setTaValue("");
                    setErrorMsg("");
                    setSuccessValid(false);
                    setTimeout(()=>setSuccessValid(true), 3000)
                })
            }
        }
        catch(error){
            setErrorMsg("Cannot send, error occurred - try again later");
            setSuccessValid(true);
        }
    }

    return (
        <div className="container d-flex flex-column justify-content-center middle">
            <div className="card shadow">
                <div className="card-header text-center">
                    <h5>Questions</h5>
                </div>
                <div className="card-body">
                    <h6 className="text-center"><i>{props.data.creatorName}'s Question</i></h6>
                    <h4 className="text-center mb-3">{props.data.question} 📃</h4>
                    <form>
                        <div className="form-group mb-3">
                            <input type="text" className="form-control" placeholder="Enter your name (Optional)" value={nameValue} onChange={handleNameValidation}/>
                            <p className="text-danger">{nameError}</p>
                        </div>
                        <div className="form-group mb-3">
                            <textarea className="form-control" placeholder="Enter your reply here" value={taValue} onChange={handleTextArea}/>
                            <p className="text-danger">{taError}</p>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary form-control" disabled={buttonValid || taButtonValid} onClick={handleSubmit}>Send</button>
                            <p className="text-danger" style={{ textAlign: "left"}}>{errorMsg}</p>
                        </div>
                        <div className="alert alert-success mt-2" role="alert" hidden={successValid} style={{textAlign: "left"}}>
                            <b>Sent</b>
                        </div>
                    </form>
                    <hr></hr>
                    <Link to='/questions'><button className="btn btn-success form-control mt-2">Create my question</button></Link>
                </div>
            </div>
        </div>
    )
}

export default QuesCreatorUser;