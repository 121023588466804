import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDYFuBRg3IYbLiquxfKVnJrC0FxOG3mI_k",
    authDomain: "funsite-in.firebaseapp.com",
    projectId: "funsite-in",
    storageBucket: "funsite-in.appspot.com",
    messagingSenderId: "581660078283",
    appId: "1:581660078283:web:629077ffd80d06bf03ab4e",
    measurementId: "G-XB2XPY3VYD"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export {auth};
export default db;