import { Link } from "react-router-dom";
import { useEffect } from "react";

function Greetings() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Greetings");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Checkout our greetings page and wish your contacts on those occasions by sharing the appropriate greeting.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/pLmQ2mKx/Greetings.jpg");
    },[])
    return (
        <div className="container">
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
            <div className="col">
                <Link to="/greetings/eid-ul-fitr" className="text-decoration-none"><div className="card h-100">
                    <img src="images\EidCard.jpg" className="card-img-top" alt="Eid-ul-Fitr"/>
                        <div className="card-body">
                            <h5 className="card-title">Eid-ul-Fitr</h5>
                            <p className="card-text">Eid Mubarak. Wish your contacts on this Eid</p>
                        </div>
                </div></Link>
            </div>
            <div className="col">
                <Link to="/greetings/ugadi" className="text-decoration-none"><div className="card h-100">
                    <img src="images\UgadiCard.jpg" className="card-img-top" alt="Eid-ul-Fitr"/>
                        <div className="card-body">
                            <h5 className="card-title">Ugadi</h5>
                            <p className="card-text">Wish your contacts Happy Ugadi.</p>
                        </div>
                </div></Link>
            </div>
        </div>
        </div>
    )
}

export default Greetings;