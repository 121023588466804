import summerTips from "../../assets/SummerTips.jpg";
import { useEffect } from "react";

function SummerTips() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Summer Tips");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Share some Tips with yours contacts for this hot summer.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/288FKc61/Summer-Tips-Card.jpg");
    },[])
    return (
        <div>
            <div className="offset-lg-3 col-lg-6 card mt-5">
                <div>
                    <img src={summerTips} className="card-img" style={{ height: "100vh" }} alt="Summer Tips" />
                    <div className="card-img-overlay">
                        <div className="card p-2 shadow" style={{ marginTop: "125px", fontWeight: "bold" }}>
                            1. Drink plenty of water and fluids, provide water to animals and birds - stay hydrated.
                        </div>
                        <div className="card p-2 shadow mt-2" style={{ fontWeight: "bold" }}>
                            2. Enjoy longer daylight hours with outdoor activities, start a physical hobby and spend time with family and friends.
                        </div>
                        <div className="card p-2 shadow mt-2" style={{ fontWeight: "bold" }}>
                            3. Apply sunscreen with at least SPF 30 to protect your skin from harmful UV rays.
                        </div>
                        <div className="card p-2 shadow mt-2" style={{ fontWeight: "bold" }}>
                            4. Wear lightweight, loose-fitting, and light-colored clothing.
                        </div>
                        <div className="card p-2 shadow mt-2 mb-2" style={{ fontWeight: "bold" }}>
                            5. Eat fresh and delicious seasonal fruits and vegetables.
                        </div>
                        <p className="text-center"><i>Share these with your family and friends</i></p>
                        <div className="text-center">
                            <a href={`whatsapp://send?text=Summer tips 👉 ${window.location.href}`} data-action="share/whatsapp/share"
                                target="_blank" rel="noreferrer"><button className="btn btn-success">WhatsApp Share</button></a>
                            <button className="btn btn-primary m-1" id="copyButton" onClick={() => {navigator.clipboard.writeText(`Summer tips 👉 ${window.location.href}`); document.getElementById("copyButton").textContent ="Copied!" }}>Copy link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SummerTips;