import { useEffect } from "react";

function PrivacyPolicy() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Privacy policy");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "View our website privacy policy.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/cHd7QXtM/logo192.jpg");
    },[])
    return (
        <div className="container pt-5">
            <div className="card shadow p-2 mt-3">
                <h1>Privacy policy</h1>
                <hr />
                <p>
                We take your privacy seriously and are committed to protecting your personal information. This privacy policy outlines how we collect, use, and protect the information you provide to us.
                    <br/>
                    <br/>
                    1. Information Collection: We only collect the data which you send to our backend when you are using the activities in the website.
                    Our service providers or advertising networks may collect your information(not from our backend and information such as ip address, device model etc..) when you use our website and we do not take any responsibility for the collection, use and disclosure of that information.
                    <br/>
                    <br/>
                    2. Use of Data: The usage of the data you send to our backend will be according to the activity. we do not use the data you sent to our backend to enhance or to improve our website. We may use the data to customize your user experience.
                    <br/>
                    <br/>
                    3. Disclosure of Data: We do not sell, trade, or otherwise transfer your data to third parties.
                    <br/>
                    <br/>
                    4. Database Data rights: We reserve all rights to view and manage your data including deleting your data without prior notice.
                    <br/>
                    <br/>
                    5. LocalStorage: We may use your localStorage of your browser to store your data when you use our website. Our service providers may also use your localStorage and we are not responsible for their use in any way.
                    <br/>
                    <br/>
                    6. Cookies: Our service providers or advertising networks may use cookies to improve our experience on our website. Cookies are small text files that are stored on your device and allow us to remember your preferences and track your usage of our website. You can disable cookies in your web browser settings, but please note that this may affect your user experience on our website and We or they may use cookies and other tracking technologies to collect information about your visits to our website and to serve advertisements based on your interests.
                    <br/>
                    <br/>
                    5. Security: We take reasonable measures to protect your data from unauthorized access, disclosure, or misuse. However, no data transmission over the internet is 100% secure, and we cannot guarantee the security of your information. We recommened not be send any sensible or personal information unless asked to into our database.
                    <br/>
                    <br/>
                    6. Children: Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information or data from children under the age of 13.
                    <br/>
                    <br/>
                    7. Changes to Privacy Policy: We reserve the right to modify or update this privacy policy at any time without prior notice. Your continued use of our website after any modifications or updates to this privacy policy will constitute your acceptance of the changes.
                    <br/>
                    <br/>
                    8. Advertising: Our website may use third-party advertising networks to display advertisements. These networks may collect information about your visits to our website and other websites in order to provide targeted advertisements.
                    <br/>
                    <br/>
                    By using our website, you acknowledge that you have read and agree to this privacy policy. If you do not agree to this privacy policy, please do not use our website.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;