import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import EidBg from "../../assets/EidBg.jpg";

function Ramzan() {
    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Eid-ul-Fitr");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Eid Mubarak. Wish your contacts on this Eid.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/RFLKPKx1/EidCard.jpg");
    },[])

    return (
        <div>
            <div className="icon-bar">
                <button className="btn btn-success" onClick={modalShow}><FontAwesomeIcon icon={faShareNodes} /></button>
            </div>
            <Modal centered show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <div className="text-center">
                    <div>
                        <a href={`whatsapp://send?text=${window.location.href}`} data-action="share/whatsapp/share"
                            target="_blank" rel="noreferrer"><button className="btn btn-success"> Share to WhatsApp</button></a>
                        <button className="btn btn-primary m-3" id="copyButton" onClick={() => { navigator.clipboard.writeText(`${window.location.href}`); document.getElementById("copyButton").textContent = "Copied!" }}>Copy link</button>
                    </div>
                </div>

                <Modal.Footer>
                    <Button variant="secondary" onClick={modalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <img src={EidBg} className="middle mt-5" alt="Eid-ul-Fitr"/>
        </div>
    )
}

export default Ramzan;