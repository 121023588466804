import React from "react";
import { useState, useEffect } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import 'firebase/firestore';
import { Link } from "react-router-dom";
import db from "../../dbConfig";
import { useNavigate } from "react-router-dom";

function Questions() {
    const [customValid, setCustomValid] = useState(true);
    const [existedUserMsg, setExistedUserMsg] = useState(true);
    const [buttonValid, setButtonValid] = useState(true);
    const [customQues, setCustomQues] = useState("");
    const [creatorName, setCreatorName] = useState("");
    const [nameValid, setNameValid] = useState(true);
    const [nameError, setNameError] = useState("");
    const [customError, setCustomError] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [quesId, setQuesId] = useState("");
    const navigate = useNavigate();
    const dbRef = doc(collection(db, "questions"));
    const replies = [];

    useEffect(() => {
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Questions");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Question your friends, family or contacts and see what they have answered. You can pick questions provided in the dropdown or customize your own question.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/52zhX2Vd/Questions.jpg");
    }, [])

    const handleNameChange = (e) => {
        const name = e.target.value;
        if (name === "" || !name.match(/^([a-zA-Z0-9]+[\s])*[a-zA-z0-9]+$/) || (name.includes("[") || name.includes("]") || name.includes("[") || name.includes("^") || name.includes("\\"))) {
            setNameValid(true);
            setCreatorName(name);
            if (name !== "") {
                setNameError("only alphabets, numbers and single space between letters are allowed");
            }
            else {
                setNameError("");
            }
        }
        else {
            setNameValid(false);
            setCreatorName(name);
            setNameError("");
        }
    }

    const custValid = (e) => {
        const userQues = e.target.value;
        setCustomQues(userQues);
        if (localStorage.getItem(userQues)) {
            setExistedUserMsg(false);
            setQuesId(localStorage.getItem(userQues));
            setButtonValid(true);
            setCustomError("");
        }
        else {
            setExistedUserMsg(true);
            setQuesId("");
            if (userQues !== "" && userQues.match(/^([a-zA-Z0-9.,]+[\s])*[a-zA-z0-9,.]+$/) && !(userQues.includes("[") || userQues.includes("]") || userQues.includes("[") || userQues.includes("^") || userQues.includes("\\"))) {
                setButtonValid(false);
                setCustomQues(userQues);
                setCustomError("");
            }
            else {
                setButtonValid(true);
                setCustomQues(userQues);
                if (userQues !== "") {
                    setCustomError("only alphabets, numbers, (.), (,) and single space between letters are allowed");
                }
                else {
                    setCustomError("");
                }
            }
        }
    }

    const handleChange = () => {
        const question = document.getElementById('questionPick').value;
        if (localStorage.getItem(question) || question === "") {
            setButtonValid(true);
            setCustomValid(true);
            if (question === "") {
                setExistedUserMsg(true);
                setQuesId("");
            }
            else {
                setExistedUserMsg(false);
                setQuesId(localStorage.getItem(question));
            }
        } else {
            setExistedUserMsg(true);
            setQuesId("");
            if (question === "Custom") {
                setCustomQues("");
                setCustomValid(false);
                setButtonValid(true);
            }
            else {
                setCustomValid(true);
                setButtonValid(false);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (customValid) {
                const pickedQues = document.getElementById('questionPick').value;
                const data = {
                    id: dbRef.id,
                    creatorName,
                    question: pickedQues,
                    time: new Date(),
                    replies
                }
                await setDoc(dbRef, data);
                localStorage.setItem(pickedQues, dbRef.id);
                setErrorMsg("");
                navigate(`./${dbRef.id}`);
            }
            else {
                const data = {
                    id: dbRef.id,
                    creatorName,
                    question: customQues,
                    time: new Date(),
                    replies
                }
                await setDoc(dbRef, data);
                localStorage.setItem(customQues, dbRef.id);
                setErrorMsg("");
                navigate(`./${dbRef.id}`);
            }
        }
        catch (error) {
            setErrorMsg("Cannot create, error occurred - try again later");
        }
    }

    return (
        <div className="container d-flex flex-column justify-content-center middle">
            <div className="card shadow text-center">
                <div className="card-header">
                    <h5>Questions</h5>
                </div>
                <div className="card-body">
                    <div style={{ textAlign: "left" }}>
                        <p><u>Steps:</u></p>
                        <p>1. Pick a question you want to ask from the drop down or write your own question by selecting 'Custom' from the drop down</p>
                        <p>2. Click on 'Create'. This creates your personal page to share with your contacts so that they can answer your question and can view those answers.</p>
                        <p>Note:- We use localStorage to identify you, so to view your replies open your personal page in the same browser you created your page. You cannot view your replies if your data in localstorage is deleted.</p>
                    </div>
                    <form>
                        <div className="form-group mb-3">
                            <input type="text" className="form-control" placeholder="Enter your name" value={creatorName} onChange={handleNameChange}/>
                            <p className="text-danger" style={{ textAlign: "left" }}>{nameError}</p>
                        </div>
                        <div className="form-group">
                            <select className="form-control mb-3" id="questionPick" onChange={handleChange}>
                                <option value="">Choose...</option>
                                <option value="Custom">Custom</option>
                                <option value="What do you think I should change in myself to become a better person">What do you think I should change in myself to become a better person</option>
                                <option value="Do you want to say something but could not">Do you want to say something but could not</option>
                                <option value="Suggest me some good books to read">Suggest me some good books to read</option>
                                <option value="Suggest me some good movies to watch">Suggest me some good movies to watch</option>
                                <option value="Suggest me some good animes to watch">Suggest me some good animes to watch</option>
                                <option value="What kind of a person do you think I am">What kind of a person do you think I am</option>
                                <option value="What are your thoughts when we met for the first time">What are your thoughts when we met for the first time</option>
                                <option value="I am bored, suggest me something">I am bored, suggest me something</option>
                            </select>
                        </div>
                        <div className="form-group mb-3" hidden={customValid}>
                            <input type="text" className="form-control" placeholder="Enter you own Question" value={customQues} onChange={custValid} />
                            <p className="text-danger" style={{ textAlign: "left" }}>{customError}</p>
                        </div>
                        <div className="form-group" style={{ textAlign: "right" }}>
                            <button className="btn btn-primary" disabled={buttonValid || nameValid} onClick={handleSubmit}>Create</button>
                            <p className="text-danger" style={{ textAlign: "left" }}>{errorMsg}</p>
                        </div>
                        <div className="text-danger">
                            Under maintenance - come back later.
                        </div>
                        <div className="alert alert-danger mt-3" role="alert" hidden={existedUserMsg} style={{ textAlign: "left" }}>
                            You already have a page with this question, delete your existing page to create new one<br />
                            To view your existing page <Link to={`/questions/${quesId}`} className="text-dark"><i>Click here</i></Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Questions;