import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function NavBar() {
  const [show, setShow] = useState(false);
  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} bg="dark" expand={expand} className="fixed-top" variant='dark'>
          <Container fluid>
            <Navbar.Brand><Link className='text-decoration-none text-light' to='/'>Funsite</Link></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShow(true)}/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              style={{ maxWidth: "70%" }}
              show={show}
              onHide={() => setShow(false)}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Funsite
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link to="/" className='nav-link' onClick={() => setShow(false)}>Home</Link> 
                  <Link to="/aboutus" className='nav-link' onClick={() => setShow(false)}>About us</Link>
                  <Link to="/privacypolicy" className='nav-link' onClick={() => setShow(false)}>Privacy policy</Link>
                  <Link to="/termsandconditions" className='nav-link' onClick={() => setShow(false)}>Terms and conditions</Link>
                  <Link to="/contactus" className='nav-link' onClick={() => setShow(false)}>Contact us</Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar;