import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

function AprilFool() {
    const [message, setMessage] = useState('');
    const [isFinished, setIsFinished] = useState(false);
    const [progress, setProgress] = useState(0);
    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);


    useEffect(() => {
        document.querySelector('meta[property="og:title"]').setAttribute("content", "April fool");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Fool your contacts on April fool's day by making them think their three recents whatsapp chats are being deleted.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/15CchGnJ/April-Fool.jpg");

        const greetings = ['Dear Friend,', 'you are daring and brave but these qualities sometimes bring losses.', 'I lost - its time for you to loss.', 'Lets suffer together my friend😁'];
        const addLetter = (i, currentMessage, currentGreeting) => {
            if (i <= currentGreeting.length) {
                setMessage(currentMessage + currentGreeting.substring(i, i + 1));
                setTimeout(() => addLetter(i + 1, currentMessage + currentGreeting.substring(i, i + 1), currentGreeting), 100);
            } else {
                setTimeout(() => {
                    if (currentMessage.length === greetings.reduce((acc, curr) => acc + curr.length, 0)) {
                        return;
                    }
                    const nextGreeting = greetings[greetings.indexOf(currentGreeting) + 1];
                    if (nextGreeting) {
                        addLetter(0, currentMessage + '\n', nextGreeting);
                    } else {
                        setIsFinished(true);
                    }
                }, 500);
            }
        };
        addLetter(0, '', greetings[0]);

    }, []);

    useEffect(() => {
        if (isFinished) {
            document.body.style.backgroundColor = "black"
            for (let progPrcnt = 0; progPrcnt <= 100; progPrcnt++) {
                if (progPrcnt <= 30) {
                    setTimeout(function () {
                        setProgress(progPrcnt);
                    }, 50 * progPrcnt);
                }
                if (30 < progPrcnt && progPrcnt <= 55) {
                    setTimeout(function () {
                        setProgress(progPrcnt);
                    }, 100 * progPrcnt);
                }
                else {
                    setTimeout(function () {
                        setProgress(progPrcnt);
                    }, 75 * progPrcnt);
                }
            }
        }
    }, [isFinished])


    return (
        <div className="middle d-flex flex-column justify-content-center container">
            <div className="card shadow">
                <div className="card-body">
                    <h6 style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>{message}</h6>
                    {isFinished && (
                        <div className="card p-2 border-dark">

                            <p className="lineUp text-center">Deleting 3 recent whatsapp chats....{progress}%</p>
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped" style={{ width: `${progress}%` }} ></div>
                            </div>
                            <div className="d-grid justify-content-end p-2">
                                <Button variant="danger" onClick={modalShow}>
                                    Cancel
                                </Button>
                            </div>
                            <p className="delayed-div" style={{fontSize: "15px"}}>Just kidding😜, Click cancel for sharing Link.</p>
                            <Modal centered show={show} onHide={modalClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>

                                <div className="text-center">
                                    <h1>April Fool😜</h1>
                                </div>
                                <div className="text-center">
                                    <div>
                                        <a href={`whatsapp://send?text=Open the link to see what awaits for you this April Fool's Day 👉 ${window.location.href}`} data-action="share/whatsapp/share"
                                            target="_blank" rel="noreferrer"><button className="btn btn-success"> Share to WhatsApp</button></a>
                                        <button className="btn btn-primary m-3" id="copyButton" onClick={() => { navigator.clipboard.writeText(`Open the link to see what awaits for you this April Fool's Day 👉 ${window.location.href}`); document.getElementById("copyButton").textContent = "Copied!" }}>Copy link</button>
                                    </div>
                                </div>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={modalClose}>Close</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AprilFool;