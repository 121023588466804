import { useEffect } from "react";

function ValentineTips() {
    useEffect(()=>{
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Valentine Tips");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Play a joke on your single friends by reminding them that they are single on this Valentines's day.");
        document.querySelector('meta[property="og:image"]').setAttribute("content", "https://i.postimg.cc/sXr59KqD/Valentine-Tips.jpg");
    },[])
    return (
        <div className="middle text-center d-flex flex-column justify-content-center">
            <h1 className="tip-styling">First go get a girlfriend/boyfriend bro🤣</h1>
            <hr/>
            <p><i>Share to your single friends</i></p>
            <div>
            <a href={`whatsapp://send?text=Valentine tips 👉 ${window.location.href}`} 	data-action="share/whatsapp/share"
		    target="_blank" rel="noreferrer"><button className="btn btn-success"> Share to WhatsApp</button></a>
            <button className="btn btn-primary m-3" id="copyButton" onClick={() => {navigator.clipboard.writeText(`Valentine tips 👉 ${window.location.href}`); document.getElementById("copyButton").textContent ="Copied!" }}>Copy link</button>
            </div>
        </div>
    );
}

export default ValentineTips;